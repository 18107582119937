import { useGetAPI, useDeleteAPI, usePatchAPI, usePostAPI } from '@api/useAPI';
import {
	UnallocatedRows,
	TShipperBilling,
	TShipperBillingDetail,
	TBillingInvoiceStatus,
	TBillingDownload,
	ShipperCarrierSearch,
	TInvoices,
	TRecheckInvoices,
	TCarrierBillingDetail,
	TCarrierBilling,
	TIssueAndClaimTableRow,
	TIssueAndClaimSidepanelRow,
	TClaimItem,
	TUpdateIssueAndClaim,
	TAccountable,
	TSubClaim,
	TTruckType,
	TTruckSize,
	TMarketPlaces,
	TMarketPlaceHoliday,
	TRule,
	THolidays,
	TShipperList,
	TShipperOverviews,
	TNonRCTIInvoices,
	TNonRCTIInvoiceDetails,
	TInvoiceAction,
	TInvoiceMinorAdjustmentPayload,
	TCarrier,
	TNonRCTIInvoiceShipment,
	TMatchOrRejectShipmentPayload,
	TCargoAdjustmentPayload,
	TInvoiceHistory,
	TInvoiceCommentPayload,
	TShipmentEOS,
	TSuperDock,
	AJAXError,
	TEOSshipment,
	TQuickStats,
	XDockAddress,
	THarmonyResponse,
	TRateCard,
	TMatrixFilter,
	TCargoAdjustmentResponse,
	TNewClaimPayload,
	TInvoiceDownload
} from '@types';

/*
 *	SHIPPER/CARRIER Lookup
 */

export const useGetShipperCarrier = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/{1}/overview/{2}?search={3}',
		domain: 'Inertia'
	});

	const triggerCallAPI = (type: 'shipper' | 'carrier', search: string, isOnboard: string = 'search') => {
		callAPI({ routes: [type, isOnboard, search] });
	}

	return {
		loadingShippersCarriers: isLoading,
		shippersCarriers: data as ShipperCarrierSearch,
		getShipperCarrierError: error,
		callGetShippersCarriers: triggerCallAPI
	}
}

/*
 *	ADMIN -> FULFILMENT -> SUPERDOCK
 */

export const useCreateSuperDock = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/v2/super-dock' });

	return {
		loadingSuperDock: isLoading,
		superdock: data?.data as TSuperDock,
		createSuperDockError: error as AJAXError,
		callCreateSuperdock: callAPI
	}
}

export const useGetSuperDocks = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/v2/super-dock' });

	return {
		loadingSuperDock: isLoading,
		superdocks: data as {
			data: TSuperDock[];
			perPage: number;
			totalRows: number;
			currentPage: number;
		},
		getSuperDocksError: error as AJAXError,
		callGetSuperdocks: callAPI
	}
}

export const useGetSuperDock = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/v2/super-dock/{1}' });

	return {
		loadingSuperDock: isLoading,
		superdock: data?.data as TSuperDock,
		getSuperDockError: error as AJAXError,
		callGetSuperdock: callAPI
	}
}

export const useUpdateSuperDock = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePatchAPI({ endpoint: '/api/v2/super-dock/{1}' });

	return {
		loadingUpdateSuperDock: isLoading,
		updateSuperdock: data?.data as TSuperDock,
		updateSuperDockError: error as AJAXError,
		clearError: clearError,
		callUpdateSuperdock: callAPI
	}
}

export const useDeleteSuperDocks = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/v2/super-dock/{1}' });

	return {
		loadingDeleteSuperDock: isLoading,
		deletedSuperdocks: data as { success: boolean },
		deleteSuperDockError: error as AJAXError,
		callDeleteSuperdock: callAPI
	}
}

export const useDeleteSuperDockShipment = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/v2/super-dock/{1}/original-shipment/{2}' });

	const triggerAPI = (props: { superDockID: string; shipmentID: string }) => {
		const {
			superDockID,
			shipmentID
		} = props;
		callAPI({ routes: [superDockID, shipmentID] });
	}

	return {
		loadingDeleteSDShipment: isLoading,
		deletedSDShipments: data as { type: string, message: string },
		deleteSDShipmentsError: error as AJAXError,
		clearError: clearError,
		callDeleteSDShipments: triggerAPI
	}
}

export const useGetSimilarShipments = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/v2/super-dock/{1}/search-similar-shipments' });

	return {
		loadingSimilarShipments: isLoading,
		similarShipments: data?.data as TEOSshipment[],
		getSimilarShipmentsError: error as AJAXError,
		callGetSimilarShipments: callAPI
	}
}

export const useGetXDockAddress = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/v2/super-dock/cross-dock-address' });

	return {
		loadingXDockAddresses: isLoading,
		XDockAddresses: data?.data as XDockAddress[],
		getXDockAddressesError: error as AJAXError,
		callGetXDockAddresses: callAPI
	}
}

export const usePostForAllocatePrice = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/v2/super-dock/price' });

	return {
		loadingAllocationPrice: isLoading,
		allocationPrice: data as { 'status': string; data: TRateCard | null },
		allocationPriceError: error as AJAXError,
		callPostForAllocationPrice: callAPI
	}
}

export const useGetHarmonyResults = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/fulfilment/carrier/search' });

	return {
		loadingHarmonyResults: isLoading,
		harmonyResults: data as THarmonyResponse,
		getHarmonyResultsError: error as AJAXError,
		callGetHarmonyResults: callAPI
	}
}

export const useGetHarmonyRecommendationResults = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/fulfilment/carrier-recommendation/search?origin_city={1}&origin_postcode={2}&destination_city={3}&destination_postcode={4}&cargo_type={5}&cargo_subtype={6}&truck_type={7}&temperature={8}&shipment_pickup_date={9}' });

	const apiCall = (
		originCity: string,
		originPostcode: string,
		destinationCity: string,
		destinationPostcode: string,
		cargoType: string,
		cargoSubType: string,
		truckType: string,
		temperature: string,
		pickupDate: string
	) => {
		callAPI({
			routes: [
				originCity,
				originPostcode,
				destinationCity,
				destinationPostcode,
				cargoType,
				cargoSubType,
				truckType,
				temperature,
				pickupDate
			]
		})
	}

	return {
		loadingHarmonyResults: isLoading,
		harmonyResults: data as THarmonyResponse,
		getHarmonyResultsError: error as AJAXError,
		callGetHarmonyResults: apiCall,
		clearHarmonyResultsError: clearError
	}
}

export const useGetHarmonyReoptimise = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/fulfilment/carrier-recommendation/re-optimise-ltc?origin_city={1}&destination_city={2}&cargo_type={3}&cargo_subtype={4}&truck_type={5}&temperature={6}' });

	const apiCall = (
		originCity: string,
		destinationCity: string,
		cargoType: string,
		cargoSubType: string,
		truckType: string,
		temperature: string
	) => {
		callAPI({
			routes: [
				originCity,
				destinationCity,
				cargoType,
				cargoSubType,
				truckType,
				temperature
			]
		})
	}

	return {
		loadingHarmonyOptimiseResults: isLoading,
		harmonyOptimiseResults: data as THarmonyResponse,
		getHarmonyOptimiseResultsError: error as AJAXError,
		callGetHarmonyOptimiseResults: apiCall,
		clearHarmonyOptimiseResultsError: clearError
	}
}

export const usePostHarmonyFeedback = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/fulfilment/carrier-recommendation/feedback' });

	return {
		loadingHarmonyFeedback: isLoading,
		harmonyFeedback: data,
		postHarmonyFeedbackError: error as AJAXError,
		callPostHarmonyFeedback: callAPI
	}
}

export const useDeleteHarmonyFeedback = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/admin/fulfilment/carrier-recommendation/delete' });

	return {
		loadingDeleteHarmonyFeedback: isLoading,
		deleteHarmonyFeedback: data,
		deleteHarmonyFeedbackError: error as AJAXError,
		callDeleteHarmonyFeedback: callAPI
	}
}

/*
 *	ADMIN -> INBOX -> QUOTES
 */

export const useGetQuotes = () => {
	const endpoint = '/api/admin/inbox/quotes'
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint });

	return {
		loadingQuotes: isLoading,
		quotes: data,
		getQuotesError: error,
		clearGetQuotesError: clearError,
		callGetQuotes: callAPI,
		apiEndpoint: endpoint
	}
}

export const useFilterQuotes = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/inbox/quotes?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterQuotes: isLoading,
		filter: data,
		getFilteredQuotesError: error,
		clearGetFilterQuotesError: clearError,
		callGetFilterQuotes: triggerAPI
	}
}

export const useDeleteQuote = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useDeleteAPI({ endpoint: `/api/admin/inbox/quote/{1}` });

	const triggerAPI = (id: string, payload: {
		reason_id: string;
		cancellation_note: string;
	}) => {
		callAPI({ routes: [id], payload });
	}

	return {
		loadingDeleteQuote: isLoading,
		deletedQuote: data,
		getDeleteQuoteError: error,
		clearDeleteQuoteError: clearError,
		callDeleteQuote: triggerAPI
	}
}

/*
 *	ADMIN -> INBOX -> NEW SHIPPERS
 */

export const useGetNewShippers = () => {
	const endpoint = '/api/admin/inbox/shippers';
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI,
	} = useGetAPI({ endpoint });

	return {
		loadingNewShippers: isLoading,
		newShippers: data,
		getNewShippersError: error,
		clearNewShipperError: clearError,
		clearNewShipperData: clearData,
		callGetNewShippers: callAPI,
		apiEndpoint: endpoint,
	}
}

export const useFilterNewShippers = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/inbox/shippers?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterNewShippers: isLoading,
		filter: data,
		getFilteredNewShippersError: error,
		clearFilterShipperError: clearError,
		clearFilterShipperData: clearData,
		callGetFilterNewShippers: triggerAPI
	}
}

export const useActivateShipper = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/shipper/{1}/shipper-activate' });

	const triggerAPI = (id: string, payload: any) => {
		callAPI({ routes: [id], payload });
	}

	return {
		loadingActivateShipper: isLoading,
		activateShipper: data,
		getActivateShipperError: error,
		clearActivateShipperError: clearError,
		clearActivateShipperData: clearData,
		callActivateShipper: triggerAPI
	}
}

export const useRejectShipper = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/shipper/reject-shipper' });

	const triggerAPI = (payload: any) => {
		callAPI({ payload });
	}

	return {
		loadingRejectShipper: isLoading,
		rejectShipper: data,
		getRejectShipperError: error,
		clearRejectShipperError: clearError,
		clearRejectShipperData: clearData,
		callRejectShipper: triggerAPI
	}
}

export const useDeleteShipper = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/shipper/delete-shipper' });

	const triggerAPI = (payload: any) => {
		callAPI({ payload });
	}

	return {
		loadingDeleteShipper: isLoading,
		deleteShipper: data,
		getDeleteShipperError: error,
		clearDeleteShipperError: clearError,
		clearDeleteShipperData: clearData,
		callDeleteShipper: triggerAPI
	}
}

/*
 *	ADMIN -> INBOX -> NEW CARRIERS
 */

export const useGetNewCarriers = () => {
	const endpoint = '/api/admin/inbox/carriers';
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useGetAPI({ endpoint });

	return {
		loadingNewCarriers: isLoading,
		newCarriers: data,
		getNewCarriersError: error,
		clearNewCarrierError: clearError,
		callGetNewCarriers: callAPI,
		clearNewCarrierData: clearData,
		apiEndpoint: endpoint,
	}
}

export const useFilterNewCarriers = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/inbox/carriers?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterNewCarriers: isLoading,
		filter: data,
		getFilteredNewCarriersError: error,
		clearFilterdCarrierError: clearError,
		clearFilterNewCarrierData: clearData,
		callGetFilterNewCarriers: triggerAPI,
	}
}

export const useActivateCarrier = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/carrier/{1}/activate' });

	const triggerAPI = (id: string, payload: any) => {
		callAPI({ routes: [id], payload });
	}

	return {
		loadingActivateCarrier: isLoading,
		activateCarrier: data,
		getActivateCarrierError: error,
		clearActivateCarrierError: clearError,
		clearActivateCarrierData: clearData,
		callActivateCarrier: triggerAPI
	}
}

export const useRejectCarrier = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/carrier/reject-carrier' });

	const triggerAPI = (payload: any) => {
		callAPI({ payload });
	}

	return {
		loadingRejectCarrier: isLoading,
		rejectCarrier: data,
		getRejectCarrierError: error,
		clearRejectCarrierError: clearError,
		clearRejectCarrierData: clearData,
		callRejectCarrier: triggerAPI
	}
}

export const useDeleteCarrier = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/carrier/delete-carrier' });

	const triggerAPI = (payload: any) => {
		callAPI({ payload });
	}

	return {
		loadingDeleteCarrier: isLoading,
		deleteCarrier: data,
		getDeleteCarrierError: error,
		clearDeleteCarrierError: clearError,
		clearDeleteCarrierData: clearData,
		callDeleteCarrier: triggerAPI
	}
}

/*
 *	ADMIN -> SHIPPER -> DASHBOARD
 */
export const useGetShipperOverview = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/shipper/overview?{1}' });

	const triggerAPI = (query:string|null = null) => {
		const apiParams = query ? { routes: [query] } : {};
		callAPI(apiParams);
	}

	return {
		loadingOverviews: isLoading,
		overviews: data as TShipperOverviews,
		getOverviewsError: error,
		callGetOverview: triggerAPI
	}
}
export const useGetShipment = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/transport/transport-shipment/{1}',
		domain: 'Inertia'
	});

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingShipmentData: isLoading,
		shipmentData: data as TShipmentEOS,
		getShipmentDataError: error,
		callGetShipmentData: triggerAPI
	}
}

/*
 *	ADMIN -> FINANCE -> SHIPPER BILLING
 */
export const useGetShipperBilling = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing' });

	return {
		loadingShipperBilling: isLoading,
		shipperBilling: data as TShipperBilling,
		getShipperBillingError: error,
		callGetShipperBilling: callAPI
	}
}
export const useFilterShipperBilling = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterShipperBilling: isLoading,
		filter: data as TShipperBilling,
		getFilteredShipperBillingError: error,
		callGetFilterShipperBilling: triggerAPI
	}
}
export const useDownloadShipperBilling = () => {
	const {
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing/{1}/download' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		downloadShipperBilling: data as TBillingDownload,
		callGetDownloadShipperBilling: triggerAPI
	}
}

export const useGetShipperBillingDetail = () => {
	const {
		isLoading,
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing/{1}/detail' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingShipperBillingDetail: isLoading,
		shipperBillingDetail: data as TShipperBillingDetail,
		callGetShipperBillingDetail: triggerAPI
	}
}
export const useFilterShipperBillingLineItems = () => {
	const {
		isLoading,
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing/{1}/detail?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingShipperBillingLineItems: isLoading,
		filter: data as TShipperBillingDetail,
		callGetFilterShipperBillingLineItems: triggerAPI
	}
}
/*
 *	ADMIN -> FINANCE -> CARRIER BILLING
 */
export const useGetCarrierBilling = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/carrier/billing' });

	return {
		loadingCarrierBilling: isLoading,
		carrierBilling: data as TCarrierBilling,
		getCarrierBillingError: error,
		callGetCarrierBilling: callAPI
	}
}
export const useFilterCarrierBilling = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/carrier/billing?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterCarrierBilling: isLoading,
		filter: data as TCarrierBilling,
		getFilteredCarrierBillingError: error,
		callGetFilterCarrierBilling: triggerAPI
	}
}
export const useDownloadCarrierBilling = () => {
	const {
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/carrier/billing/{1}/download' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		downloadCarrierBilling: data as TBillingDownload,
		callGetDownloadCarrierBilling: triggerAPI
	}
}

export const useGetCarrierBillingDetail = () => {
	const {
		isLoading,
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing/{1}/detail' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingCarrierBillingDetail: isLoading,
		carrierBillingDetail: data as TCarrierBillingDetail,
		callGetCarrierBillingDetail: triggerAPI
	}
}
export const useFilterCarrierBillingLineItems = () => {
	const {
		isLoading,
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/carrier/billing/{1}/detail?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingCarrierBillingLineItems: isLoading,
		filter: data as TCarrierBillingDetail,
		callGetFilterCarrierBillingLineItems: triggerAPI
	}
}
export const usePatchInvoiceStatus = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/finance/invoice/{1}/status' });

	const triggerAPI = (id: string, payload: any) => {
		callAPI({ routes: [id], payload });
	}

	return {
		loadingInvoiceStatus: isLoading,
		getInvoiceStatus: data as TBillingInvoiceStatus,
		getInvoiceStatusError: error,
		callUpdateInvoiceStatus: triggerAPI
	}
}

/*
 * ADMIN -> FULFILMENT -> UNALLOCATED
 */

export const useGetQuickStats = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/fulfilment/quick-stats' });

	return {
		loadingQuickStats: isLoading,
		quickStats: data?.data as TQuickStats[],
		quickStatsError: error,
		callGetQuickStats: callAPI
	}
}

export const useGetUnallocated = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/fulfilment/unallocated' });

	return {
		loadingUnallocated: isLoading,
		unallocated: data as {
			rows: UnallocatedRows[];
			perPage: number;
			totalRows: number;
			currentPage: number;
		},
		unallocatedError: error,
		clearUnallocatedError: clearError,
		callGetUnallocated: callAPI
	}
}

export const useGetMatrixFilter = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/operations-matrix' });

	return {
		loadingMatrix: isLoading,
		matrixs: data as TMatrixFilter[],
		matrixError: error,
		clearMatrixError: clearError,
		callGetMatrix: callAPI
	}
}

export const useAssignTo = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/fulfilment/shipment/{1}/assign' });

	const triggerAPI = (shipment_id: number, payload: any) => {
		callAPI({ routes: [`${shipment_id}`], payload });
	}

	return {
		loadingAssignTo: isLoading,
		assignedTo: data,
		assignedToError: error,
		clearAssignedToError: clearError,
		callAssignTo: triggerAPI
	}
}

export const useUnassignTo = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/fulfilment/shipment/{1}/unassign' });

	const triggerAPI = (shipment_id: number) => {
		callAPI({ routes: [`${shipment_id}`] });
	}

	return {
		loadingUnassignTo: isLoading,
		unassignedTo: data,
		unassignedToError: error,
		clearUnassignedToError: clearError,
		callUnassignTo: triggerAPI
	}
}

export const useUpdateOrderNumber = () => {
	const {
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/shipment/update-order-number' });

	return {
		updateOrderNumber: data,
		updateOrderNumberError: error as AJAXError,
		clearError,
		callUpdateOrderNumber: callAPI
	}
}

/*
 *	ADMIN -> FINANCE -> ISSUES AND CLAIMS
 */

export const useGetIssueAndClaims = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/claimLines' });

	return {
		loadingIssueAndClaims: isLoading,
		issueAndClaims: data?.rows as TIssueAndClaimTableRow[],
		getIssueAndClaimsError: error,
		callIssueAndClaims: callAPI
	}
}

export const usePostIssueAndClaims = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/admin/transport/transport-shipment/{1}/claims/', });
	 const triggerAPI = (obj: {
		shipmentId: number,
		payload: {
			claim_lines: TNewClaimPayload[]
		}
	}) => {
		callAPI({ routes: [`${obj.shipmentId}`], payload: obj.payload });
	}

	return {
		loadingPostIssueAndClaim: isLoading,
		postIssueAndClaimData: data,
		getPostIssueAndClaimError: error,
		callPostIssueAndClaim: triggerAPI
	}
}

export const useUpdateClaimLines = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/finance/claims/{1}/claimLines/{2}' });

	const triggerAPI = (claim: number, claimLine: number, payload: any) => {
		callAPI({ routes: [`${claim}`, 	`${claimLine}`], payload });
	}
	return {
		loadingUpdateClaimLines: isLoading,
		updateClaimLines: data as TUpdateIssueAndClaim,
		getUpdateClaimLinesError: error,
		callUpdateClaimLines: triggerAPI
	}
}

export const useGetAccountables = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/transport/accountables',
		domain: 'Inertia'
	});

	return {
		loadingAccountables: isLoading,
		accountables: data as TAccountable[],
		getAccountablesError: error,
		callAccountables: callAPI
	}
}

export const useGetClaimSubItems = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/transport/claim-sub-items?claim_master_id={1}',
		domain: 'Inertia'
	});

	const triggerAPI = (claim_master_id: number) => {
		callAPI({ routes: [`${claim_master_id}`] });
	}
	return {
		loadingClaimSubItems: isLoading,
		claimSubItems: data as TSubClaim[],
		getClaimSubItemsError: error,
		callClaimSubItems: triggerAPI
	}
}

export const useGetClaimItems = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/transport/claim-items',
		domain: 'Inertia'
	});

	return {
		loadingClaimItems: isLoading,
		claimItems: data as TClaimItem[],
		getClaimItemsError: error,
		callClaimItems: callAPI
	}
}


export const useDeleteClaimItem = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/admin/finance/claims/{1}/claimLines/{2}' });

	const triggerAPI = (claim:number, claimLine:number) => {
		callAPI({ routes: [`${claim}`, 	`${claimLine}`] });
	}

	return {
		loadingDeleteClaimItem: isLoading,
		deletedClaimItem: data as TUpdateIssueAndClaim,
		getDeleteClaimItemError: error,
		callDeleteClaimItem: triggerAPI
	}
}

export const useFilterIssueAndClaims = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/claimLines?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterIssueAndClaims: isLoading,
		filter: data?.rows as TIssueAndClaimTableRow[],
		getFilteredIssueAndClaimsError: error,
		callGetFilterIssueAndClaims: triggerAPI
	}
}

/*
 *	ADMIN -> FINANCE -> INVOICE REPORT
 */

export const useGetInvoices = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/invoice-audit' });

	return {
		loadingInvoices: isLoading,
		invoices: data as TInvoices,
		getInvoicesError: error,
		callGetInvoices: callAPI
	}
}

export const useRecheckInvoices = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/finance/invoice-audit/{1}/recheck' });

	const triggerAPI = (id: number) => {
		callAPI({ routes: [`${id}`] });
	}

	return {
		loadingReCheckInvoices: isLoading,
		reCheckInvoices: data as TRecheckInvoices,
		getReCheckInvoicesError: error,
		callRecheckInvoices: triggerAPI
	}
}


export const useResolveInvoices = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/finance/invoice-audit/{1}/resolve' });

	const triggerAPI = (id: number) => {
		callAPI({ routes: [`${id}`] });
	}

	return {
		loadingResolveInvoices: isLoading,
		resolveInvoices: data as TRecheckInvoices,
		getResolveInvoicesError: error,
		callResolveInvoices: triggerAPI
	}
}
export const useFilterInvoiceReport = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/invoice-audit?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterInvoiceReport: isLoading,
		filter: data?.rows as TInvoices,
		getFilteredInvoiceReportError: error,
		callGetFilterInvoiceReport: triggerAPI
	}
}

/*
 *	ADMIN -> FINANCE -> NON-RCTI INVOICES
 */
export const useGetNonRCTITableInvoices = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/finance/invoice/nonrcti{1}',
		domain: 'Inertia'
	});
	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}
	return {
		loadingInvoices: isLoading,
		invoices: data as TNonRCTIInvoices,
		getInvoicesError: error,
		callGetInvoices: triggerAPI
	}
}

export const useGetAllNonRCTITableInvoices = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/finance/invoice/all{1}',
		domain: 'Inertia'
	});
	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}
	return {
		loadingAllInvoices: isLoading,
		allInvoices: data as TNonRCTIInvoices,
		getAllInvoicesError: error,
		callGetAllInvoices: triggerAPI
	}
}

export const useGetNonRCTIInvoiceDetail = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/finance/invoice/{1}/details',
		domain: 'Inertia'
	});

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingInvoiceDetails: isLoading,
		invoiceDetails: data?.data as TNonRCTIInvoiceDetails,
		getInvoiceDetailsError: error,
		callGetInvoiceDetails: triggerAPI
	}
}

export const useUpdateInvoiceStatus = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({
		endpoint: '/admin/finance/invoice/status',
		domain: 'Inertia'
	});
	const triggerAPI = (payload: TInvoiceAction) => {
		callAPI({ payload });
	}
	return {
		loadingUpdateInvoiceStatus: isLoading,
		updateInvoiceStatusData: data as TNonRCTIInvoiceDetails,
		getUpdateInvoiceStatusError: error,
		callUpdateInvoiceStatus: triggerAPI
	}
}

export const useAssignInvoice = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({
		endpoint: '/admin/finance/invoice/{1}/assign',
		domain: 'Inertia'
	});
	const triggerAPI = (payload: {
		invoiceId: string,
		comments: string,
	}) => {
		callAPI({ routes: [payload.invoiceId], payload });
	}
	return {
		loadingAssignInvoice: isLoading,
		assignInvoiceData: data as TNonRCTIInvoiceDetails,
		getAssignInvoiceError: error,
		callAssignInvoice: triggerAPI
	}
}

export const usePatchInvoiceMinorAdjustment = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePatchAPI({
		endpoint: '/admin/finance/invoice/adjustment',
		domain: 'Inertia'
	});
	const triggerAPI = (payload: TInvoiceMinorAdjustmentPayload) => {
		callAPI({ payload });
	}
	return {
		loadingPatchInvoiceMinorAdjustment: isLoading,
		patchInvoiceMinorAdjustment: data?.data as TNonRCTIInvoiceDetails,
		getPatchInvoiceMinorAdjustmentError: error,
		callPatchInvoiceMinorAdjustment: triggerAPI
	}
}

export const useGetInvoiceShipments = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/invoice?search={1}&carrierId={2}' });

	const triggerAPI = (searchValue: string, carrierId: string) => {
		callAPI({ routes: [searchValue, carrierId] });
	}

	return {
		loadingInvoiceShipments: isLoading,
		invoiceShipments: data?.data as TNonRCTIInvoiceShipment[],
		getInvoiceShipmentsError: error,
		callGetInvoiceShipments: triggerAPI
	}
}

export const useGetInvoiceShipmentSuggestions = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/invoice/{1}/suggestions' });

	const triggerAPI = (invoiceId: string) => {
		callAPI({ routes: [invoiceId] });
	}

	return {
		loadingInvoiceSuggestions: isLoading,
		invoiceSuggestions: data?.data as TNonRCTIInvoiceShipment[],
		getInvoiceSuggestionsError: error,
		callGetInvoiceSuggestions: triggerAPI
	}
}

export const useMatchShipmentToInvoice = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/finance/invoice/match' });
	const triggerAPI = (payload: TMatchOrRejectShipmentPayload) => {
		callAPI({ payload });
	}
	return {
		loadingMatchShipmentToInvoice: isLoading,
		matchShipmentToInvoiceData: data,
		getMatchShipmentToInvoiceError: error,
		callMatchShipmentToInvoice: triggerAPI
	}
}

export const useRejectShipmentSuggestion = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/finance/invoice/reject' });
	const triggerAPI = (payload: TMatchOrRejectShipmentPayload) => {
		callAPI({ payload });
	}
	return {
		loadingRejectShipmentSuggestion: isLoading,
		rejectShipmentSuggestionData: data,
		getRejectShipmentSuggestionError: error,
		callRejectShipmentSuggestion: triggerAPI
	}
}

export const useUnmatchShipment = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/finance/invoice/{1}/unmatch' });
	const triggerAPI = (invoiceLineID: number) => {
		callAPI({ routes: [String(invoiceLineID)] });
	}
	return {
		loadingUnmatchShipment: isLoading,
		unmatchShipmentData: data,
		getUnmatchShipmentError: error,
		callUnmatchShipment: triggerAPI
	}
}

export const useGetRejectionReasons = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/finance/invoice/rejection-reasons',
		domain: 'Inertia'
	});
	return {
		loadingRejectionReasons: isLoading,
		rejectionReasonsData: data as string[],
		getRejectionReasonsError: error,
		callGetRejectionReasons: callAPI
	}
}

export const useGetAdjustmentReasons = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/finance/invoice/adjustment-reasons',
		domain: 'Inertia'
	});
	return {
		loadingAdjustmentReasons: isLoading,
		adjustmentReasonsData: data as string[],
		getAdjustmentReasonsError: error,
		callGetAdjustmentReasons: callAPI
	}
}
/*
 *--
 * ADMIN -> ISSUES AND CLAIMS
 */

export const useGetShipmentIssuesAndClaims = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/transport/transport-shipment/{1}/claims',
		domain: 'Inertia'
	});

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingshipmentIssuesAndClaims: isLoading,
		shipmentIssuesAndClaims: data?.data as TIssueAndClaimSidepanelRow[],
		getShipmentIssuesAndClaimsError: error,
		callGetShipmentIssuesAndClaims: triggerAPI
	}
}

export const useGetClaimAccountables = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/transport/accountables',
		domain: 'Inertia'
	});

	return {
		loadingClaimAccountables: isLoading,
		claimAccountables: data as TAccountable[],
		getClaimAccountablesError: error,
		callGetClaimAccountables: callAPI
	}
}


/*
 *	ADMIN -> CONFIGURATION -> TRUCK TYPE
 */
export const useGetTruckTypes = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/configuration/trucktypes' });

	return {
		loadingTruckType: isLoading,
		truckType: data?.trucktype as TTruckType[],
		getTruckTypeError: error,
		callGetTruckType: callAPI
	}
}

export const useGetTruckSize = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/configuration/trucktypes' });

	return {
		loadingTruckSize: isLoading,
		truckSize: data?.trucksize as TTruckSize[],
		getTruckSizeError: error,
		callGetTruckSize: callAPI
	}
}

/*
 *	ADMIN -> CONFIGURATION -> MARKETPLACE
 */

export const useMarketPlace = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/configuration/marketplace' });

	return {
		loadingMarketPlace: isLoading,
		marketPlaces: data as TMarketPlaces,
		getMarketPlaceError: error,
		callGetMarketPlaces: callAPI
	}
}

export const useUpdateMarketPlace = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/marketplaceRuleUpdate' });
	const triggerAPI = (payload: TRule) => {
		callAPI({ payload });
	}
	return {
		loadingUpdateMarketPlace: isLoading,
		updateMarketPlaces: data as TMarketPlaceHoliday,
		getUpdateMarketPlaceError: error,
		callUpdateMarketPlaces: triggerAPI
	}
}

export const useCreateMarketPlaceHoliday = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/marketplaceHolidayCreate' });
	const triggerAPI = (payload: THolidays) => {
		callAPI({ payload });
	}
	return {
		loadingCreateMarketPlaceHoliday: isLoading,
		createMarketPlaceHoliday: data as TMarketPlaceHoliday,
		getCreateMarketPlaceHolidayError: error,
		callCreateMarketPlaceHoliday: triggerAPI
	}
}

export const useUpdateMarketPlaceHoliday = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/marketplaceHolidayUpdate' });
	const triggerAPI = (payload: THolidays) => {
		callAPI({ payload });
	}
	return {
		loadingUpdateMarketPlaceHoliday: isLoading,
		updateMarketPlaceHoliday: data as TMarketPlaceHoliday,
		getUpdateMarketPlaceHolidayError: error,
		callUpdateMarketPlaceHoliday: triggerAPI
	}
}

export const useDeleteMarketPlaceHoliday = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/marketplaceHolidayDelete' });
	const triggerAPI = (payload: THolidays) => {
		callAPI({ payload });
	}
	return {
		loadingDeleteMarketPlaceHoliday: isLoading,
		deleteMarketPlaceHoliday: data as TMarketPlaceHoliday,
		getDeleteMarketPlaceHolidayError: error,
		callDeleteMarketPlaceHoliday: triggerAPI
	}
}
export const useGetShipperList = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/shippers' });
	return {
		loadingShipperList: isLoading,
		shipperList: data?.data as TShipperList,
		getShipperListError: error,
		callGetShipperList: callAPI
	}
}

export const useGetCarrierList = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/carriers',
		domain: 'Inertia'
	});
	return {
		loadingCarrierList: isLoading,
		carrierList: data?.data as TCarrier[],
		getCarrierListError: error,
		callGetCarrierList: callAPI
	}
}


export const usePostCargoAdjustment = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({
		endpoint: '/admin/transport/transport-shipment/pallet-adjustment',
		domain: 'Inertia'
	});

	const triggerAPI = (payload: TCargoAdjustmentPayload) => {
		callAPI({ payload });
	}
	return {
		loadingCargoAdjustment: isLoading,
		cargoAdjustmentData: data?.data as TCargoAdjustmentResponse,
		getCargoAdjustmentError: error,
		callGetCargoAdjustment: triggerAPI
	}
}

export const useGetInvoiceHistory = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/finance/invoice/{1}/log',
		domain: 'Inertia'
	});

	const triggerCallAPI = (id: string) => {
		callAPI({ routes: [id] });
	}
	return {
		loadingInvoiceHistory: isLoading,
		invoiceHistory: data?.data as TInvoiceHistory[],
		getInvoiceHistoryError: error,
		callGetInvoiceHistory: triggerCallAPI
	}
}

export const usePostInvoiceComment = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({
		endpoint: '/admin/finance/invoice/{1}/comment',
		domain: 'Inertia'
	});
	const triggerAPI = (invoiceId: string, payload: TInvoiceCommentPayload) => {
		callAPI({ routes: [invoiceId], payload });
	}
	return {
		loadingPostInvoiceComment: isLoading,
		postInvoiceCommentData: data as TNonRCTIInvoiceDetails,
		getPostInvoiceCommentError: error,
		callPostInvoiceComment: triggerAPI
	}
}

export const useDownloadInvoice = () => {
	const {
		data,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/finance/invoice/{1}/download',
		domain: 'Inertia'
	});

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		downloadInvoice: data as TInvoiceDownload,
		callGetDownloadInvoice: triggerAPI
	}
}

export const usePostInvoice = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({
		endpoint: '/admin/finance/invoice/upload',
		headers: { 'Content-Type': 'multipart/form-data', },
		domain: 'Inertia'
	});

	const triggerAPI = (payload: FormData) => {
		callAPI({ payload });
	}

	return {
		loadingPostInvoice: isLoading,
		postInvoiceData: data as any,
		getPostInvoiceError: error,
		callPostInvoice: triggerAPI
	}
}
