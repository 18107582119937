import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

type TProps = {
	borderColor?: string;
	fillColor?: string;
	className?: string;
	style?: React.CSSProperties;
}

export const CheckBoxDisabledUnchecked = (props:TProps) => {
	const {
		borderColor = 'var(--neutral-04)',
		fillColor = 'var(--neutral-03)',
		style,
		className
	} = props;
	return (
		<SvgIcon style={style} className={className}>
			<path fill={fillColor} d='M 4 4 L 4 20 L 20 20 L 20 4 L 4 4' />
			<path fill={borderColor} d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z'></path>
		</SvgIcon>
	);
}
